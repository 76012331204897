import { i18n } from "~/plugins/translator";

export const getTypeFromSector = function (sector, i18n) {
  switch (sector) {
    case "RESIDENTIAL_FREE_RENT":
      return i18n.t("RENTAL_SECTOR_RESIDENTIAL");
    case "SHOP":
      return i18n.t("RENTAL_SECTOR_COMMERCIAL");
    case "INDUSTRY":
      return i18n.t("RENTAL_SECTOR_INDUSTRY");
    case "OFFICE":
      return i18n.t("RENTAL_SECTOR_OFFICE");
    case "OTHER_BUSINESS":
      return i18n.t("RENTAL_SECTOR_OTHER_BUSINESS");
    case "RESIDENTIAL_COST":
      return i18n.t("RENTAL_SECTOR_RESIDENTIAL_COST");
    case "RESIDENTIAL_SMALL":
      return i18n.t("RENTAL_SECTOR_RESIDENTIAL_SMALL_HOUSE_RENTAL");
    case "RESIDENTIAL_RENTAL":
      return i18n.t("RENTAL_SECTOR_RESIDENTIAL_RENTAL_VALUE");
  }

  return i18n.t("VALUE_NOT_REGISTERED");
};

export const colorBySectorId = function (sectorId, darkerColor) {
  switch (sectorId) {
    case "RESIDENTIAL_FREE_RENT":
      return darkerColor ? "--color-purple-900" : "--color-purple-500";

    case "SHOP":
      return darkerColor ? "--color-green-900" : "--color-green-500";

    case "OFFICE":
      return darkerColor ? "--color-yellow-900" : "--color-yellow-500";

    case "INDUSTRY":
      return darkerColor ? "--color-black-900" : "--color-black-500";

    case "OTHER_BUSINESS":
      return darkerColor ? "--color-supportive-3-900" : "--color-supportive-3-500";

    case "RESIDENTIAL_SMALL":
      return darkerColor ? "--color-brown-900" : "--color-brown-500";

    case "RESIDENTIAL_RENTAL":
      return darkerColor ? "--color-blue-900" : "--color-blue-500";

    case "RESIDENTIAL_COST":
      return darkerColor ? "--color-pink-900" : "--color-pink-500";

    default:
      return darkerColor ? "--color-green-900" : "--color-green-400";
  }
};

export function colorNameBySectorId(sectorId) {
  switch (sectorId) {
    case "RESIDENTIAL_FREE_RENT":
      return "purple";

    case "SHOP":
      return "green";

    case "OFFICE":
      return "yellow";

    case "INDUSTRY":
      return "black";

    case "OTHER_BUSINESS":
      return "supportive-3";

    case "RESIDENTIAL_SMALL":
      return "brown";

    case "RESIDENTIAL_RENTAL":
      return "blue";

    case "RESIDENTIAL_COST":
      return "pink";

    default:
      return "green";
  }
}

export const getReferenceNumberOfUnits = function (referenceData) {
  let min = 0;
  let max = 0;

  Object.values(referenceData).forEach((d) => {
    if (d.numberOfReferences.min != null) {
      if (d.numberOfReferences.max != null) {
        min += d.numberOfReferences.min;
        max += d.numberOfReferences.max;
      } else {
        min += d.numberOfReferences.min;
        max += d.numberOfReferences.min;
      }
    }
  });

  return {
    numberOfUnitsString: formatMinMax(min != 0 ? min : null, max != 0 && max != min ? max : null),
  };
};

export const getReferenceTotalArea = function (referenceData) {
  if (referenceData == null) {
    return {};
  }

  return { totalArea: Object.values(referenceData).reduce((acc, cur) => acc + parseInt(cur.squareMeterSum, 10), 0) };
};

export const getReferenceDistanceToUnits = function (referenceData) {
  if (referenceData == null) {
    return {};
  }

  return { distanceToUnits: Math.max(...Object.values(referenceData).map((d) => d.calculationRadius)) };
};

export const getReferenceConfidence = function (referenceData) {
  if (referenceData == null) {
    return {};
  }

  const out = [];

  Object.entries(referenceData).forEach(([k, d]) => {
    out.push({
      sector: k,
      numberOfReferences: formatMinMax(d.numberOfReferences.min, d.numberOfReferences.max),
      squareMeterSum: d.squareMeterSum,
      calculationRadius: d.calculationRadius,
    });
  });

  return out;
};

export const getReferenceMovingInYear = function (referenceData) {
  const out = {};

  Object.entries(referenceData).forEach(([k, d]) => {
    d.movingInYears.forEach((y) => {
      if (out[formatMinMax(y.min, y.max)] == null) {
        out[formatMinMax(y.min, y.max)] = [];
      }
      out[formatMinMax(y.min, y.max)].push({
        sector: k,
        percentage: y.percentage,
      });
    });
  });

  const labels = Object.keys(out).sort((a, b) => {
    const aMin = parseInt(a.split("-")[0], 10);
    const bMin = parseInt(b.split("-")[0], 10);

    if (aMin < bMin) {
      return -1;
    } else if (aMin > bMin) {
      return 1;
    } else {
      return 0;
    }
  });

  const series = [];
  const colors = [];

  Object.keys(referenceData).forEach((k) => {
    series.push({
      name: getTypeFromSector(k, i18n),
      data: labels.map((l) => out[l].find((s) => s.sector === k)?.percentage ?? 0),
    });
    colors.push(colorBySectorId(k));
  });

  return {
    colors: colors,
    labels: labels,
    series: series,
  };
};

export const getReferenceConstructionYear = function (referenceData) {
  const out = {};

  Object.entries(referenceData).forEach(([k, d]) => {
    d.constructionYears.forEach((y) => {
      if (out[formatMinMax(y.min, y.max)] == null) {
        out[formatMinMax(y.min, y.max)] = [];
      }
      out[formatMinMax(y.min, y.max)].push({
        sector: k,
        percentage: y.percentage,
      });
    });
  });

  const labels = Object.keys(out).sort((a, b) => {
    const aMin = parseInt(a.split("-")[0], 10);
    const bMin = parseInt(b.split("-")[0], 10);

    if (aMin < bMin) {
      return -1;
    } else if (aMin > bMin) {
      return 1;
    } else {
      return 0;
    }
  });

  const series = [];
  const colors = [];

  Object.keys(referenceData).forEach((k) => {
    series.push({
      name: getTypeFromSector(k, i18n),
      data: labels.map((l) => out[l].find((s) => s.sector === k)?.percentage ?? 0),
    });
    colors.push(colorBySectorId(k));
  });

  return {
    colors: colors,
    labels: labels,
    series: series,
  };
};

export const RENT_LEVELS = {
  APARTMENT: "Etagebolig",
  TERRACED_HOUSE: "Rækkehus",
  DETACHED_RESIDENCE: "Fritliggende bolig",
  OTHER: "Uoplyst",
};

const getTranslationKeyForRentLevel = (level) => {
  switch (level) {
    case RENT_LEVELS.APARTMENT:
      return "RENT_LEVELS_APARTMENT";
    case RENT_LEVELS.TERRACED_HOUSE:
      return "RENT_LEVELS_TERRACED_HOUSE";
    case RENT_LEVELS.DETACHED_RESIDENCE:
      return "RENT_LEVELS_DETACHED_RESIDENCE";
    case RENT_LEVELS.OTHER:
      return "RENT_LEVELS_DETACHED_OTHER";
    default:
      return "Unknown";
  }
};

export const getReferenceResidentialConstructions = function (referenceData) {
  const out = {};
  const sectorsWithUsageType = ["RESIDENTIAL_SMALL", "RESIDENTIAL_RENTAL", "RESIDENTIAL_COST", "RESIDENTIAL_FREE_RENT"];

  Object.entries(referenceData).forEach(([k, d]) => {
    d.residentialConstructions.forEach((y) => {
      if (out[k] == null) {
        out[k] = [];
      }
      out[k].push({
        type: y.name,
        percentage: y.percentage,
      });
    });
  });

  const dataLabels = [];
  const data = {};

  Object.entries(out).forEach(([k, d]) => {
    d.forEach((y) => {
      if (sectorsWithUsageType.includes(k)) {
        if (!dataLabels.includes(k)) {
          dataLabels.push(k);
        }

        if (data[y.type] == null) {
          data[y.type] = [];
        }
        data[y.type].push(y.percentage);
      }
    });
  });

  const series = Object.entries(data).map(([k, d]) => {
    return {
      name: i18n.t(getTranslationKeyForRentLevel(k)),
      data: d,
    };
  });

  const labels = dataLabels.map((l) => getTypeFromSector(l, i18n));

  return {
    labels: labels,
    series: series,
  };
};

export const getReferenceSizeByType = function (referenceData) {
  const out = {};
  const labels = [];
  Object.entries(referenceData).forEach(([k, d]) => {
    out[k] = d.referenceSizes.map((s) => Math.round(s.percentage));

    // Populate labels just once
    if (labels.length === 0) {
      labels.push(...d.referenceSizes.map((s) => formatMinMax(s.min, s.max)));
    }
  });

  return {
    labels: labels,
    series: Object.entries(out).map(([k, d]) => {
      return {
        name: getTypeFromSector(k, i18n),
        type: k,
        data: d,
      };
    }),
  };
};

const formatMinMax = function (min, max) {
  if (min != null && max != null) {
    return `${min}-${max}`;
  } else if (min != null) {
    return `${min}+`;
  } else if (max != null) {
    return `< ${max}`;
  } else {
    return "";
  }
};
